import React, { useState } from 'react';

const Doors = ({ updateProgress }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        updateProgress(100);
    };

    return (
        <div className='mb-5'>
            <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
             How many door slabs need painting?
            </p>
            <div className="flex gap-5 mt-5 border border-white border-opacity-50 rounded-md px-5 py-3"
                style={{ background: "linear-gradient(to top left, rgba(95, 145, 124, 1.0), rgba(67, 118, 143, 1.0))" }}
            >
                <div className="rounded-md px-3 py-2 w-full">
                    {/* <label htmlFor="doorSlabs" className="text-white">Select the number of door slabs:</label> */}
                    <select id="doorSlabs" className="form-select block w-full mt-1"
                        value={selectedOption} onChange={handleOptionChange}>
                        <option value="">Select an option</option>
                        <option value="1">1 Door Slab</option>
                        <option value="2">2 Door Slabs</option>
                        <option value="3">3 Door Slabs</option>
                        <option value="4">4 Door Slabs</option>
                        <option value="5">5 Door Slabs</option>
                        <option value="6">6 Door Slabs</option>
                        <option value="7">7 Door Slabs</option>
                        <option value="8">8 Door Slabs</option>
                        <option value="9">9 Door Slabs</option>
                        <option value="10">10 Door Slabs</option>
                        <option value="11">11 Door Slabs</option>
                        <option value="12">12 Door Slabs</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Doors;
