import React, { useState } from 'react';
import Question6 from './Question6';

const Questio5 = ({ updateProgress }) => {
    const [selectedOption5, setSelectedOption5] = useState(null);
    const [Showquestion6, setShowquestion6] = useState(false);

    const handleOptionChange5 = (event) => {
        setSelectedOption5(event.target.value);
        updateProgress(100);
        setShowquestion6(true);
    };

    return (
        <div className='mb-5'>
            <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
                5. Do you need it painted on a specific date?
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5 border border-white border-opacity-50 rounded-md px-5 py-3"
                style={{ background: "linear-gradient(to top left, rgba(95, 145, 124, 1.0), rgba(67, 118, 143, 1.0))" }}
            >
                <div className="rounded-md px-3 py-2 flex">
                    <input type="radio" id="refreshColors5" name="paintJob5" value="refreshColors5" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out "
                        checked={selectedOption5 === "refreshColors5"} onChange={handleOptionChange5} />
                    <div className='flex flex-col'>
                        <label htmlFor="refreshColors5" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>No, I'm flexible</h4>
                        </label>
                        <span className="text-white ml-2">5% OFF</span>
                    </div>
                </div>
                <div className="rounded-md px-3 py-2 flex">
                    <input type="radio" id="colorChange5" name="paintJob5" value="colorChange5" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out"
                        checked={selectedOption5 === "colorChange5"} onChange={handleOptionChange5} />
                    <div className='flex flex-col'>
                        <label htmlFor="colorChange5" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Yes, I need it painted on/by a specific date</h4>
                        </label>
                        <span className="text-white ml-2">FREE</span>
                    </div>
                </div>
            </div>
            {Showquestion6 && <Question6 updateProgress={updateProgress} />}
        </div>
    );
};

export default Questio5;
