import React, { useState } from 'react'
import Bachlor from "../assets/icons/bed.png"
import Room1 from "../assets/icons/1roomsbed.png"
import Room2 from "../assets/icons/2roomsbed.png"
import Room3 from "../assets/icons/3roomsbed.png"
import Questio3 from './Questio3'

const Wholebutton = ({ updateProgress }) => {

    const [showQuestion3, setShowQuestion3] = useState(false);
    const [activeButton, setActiveButton] = useState(null);

    const handleClick = (buttonName) => {
        updateProgress(67);
        setShowQuestion3(true);
        setActiveButton(buttonName);
    };
    return (
        <div>
            <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
                2. How large is your condo?
            </p>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-center border border-white border-opacity-50 mb-10'>
                <button
                    className={`flex flex-col bg-gradient-to-t 
                    ${activeButton === 'bachlor' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
                    hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r
                  border-white border-opacity-50`}
                    onClick={() => handleClick('bachlor')}
                >
                    <img src={Bachlor} alt="Whole Room" className="h-20 w-20 mb-2" />
                    <p className="text-white">Bachelor</p>
                </button>
                <button
                    className={`flex flex-col bg-gradient-to-t 
                    ${activeButton === '1bedroom' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
                    hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r
                  border-white border-opacity-50`}
                    onClick={() => handleClick('1bedroom')}
                >
                    <img src={Room1} alt="Whole Room" className="h-20 w-20 mb-2" />
                    <p className="text-white">1 Bedroom</p>
                </button>
                <button
                    className={`flex flex-col bg-gradient-to-t 
                    ${activeButton === '2bedroom' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
                    hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r
                  border-white border-opacity-50`}
                    onClick={() => handleClick('2bedroom')}
                >
                    <img src={Room2} alt="Whole Room" className="h-20 w-20 mb-2" />
                    <p className="text-white">2 Bedroom</p>
                </button>
                <button
                    className={`flex flex-col bg-gradient-to-t 
                    ${activeButton === '3bedroom' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
                    hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r
                  border-white border-opacity-50`}
                    onClick={() => handleClick('3bedroom')}
                >
                    <img src={Room3} alt="Whole Room" className="h-20 w-20 mb-2" />
                    <p className="text-white">3 Bedroom</p>
                </button>
            </div>

            {showQuestion3 && <Questio3 updateProgress={updateProgress} />}
        </div>
    )
}

export default Wholebutton;