import React, { useState } from 'react';
import Modelimg from "../assets/images/model.jpg";
import { X } from 'lucide-react';

const Modal = () => {
    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-20">
                    <div className="bg-gradient-to-br from-gray-200 to-white rounded-lg shadow-xl transform transition-all grid grid-cols-1 lg:grid-cols-2 w-[70vw]"
                    >
                        <div className="w-full hidden md:block h-[50vh] lg:h-full">
                            <img src={Modelimg} alt="Paint" className="w-full h-full object-cover" />
                        </div>
                        <div className="w-full h-[50vh] lg:h-full p-6 justify-center items-center flex flex-col">
                            <h2 className="lg:text-3xl font-bold mb-6 text-gray-800 md:text-lg">
                                Summer offer 2024 <br/> Paint your Bachelor ,Only 999$
                            </h2>
                            {/* <p className='block py-3 px-6 text-md md:text-lg md:text-[20px] sm:text-[15px] lg:text-[1vw] font-[100] bg-gray-800 text-white rounded-[200px] '>
                                Get a FREE Quote Now!
                            </p> */}
                        </div>
                        <button onClick={closeModal} className="absolute top-0 right-0 p-3 ">
                            <X color='red' size={20} />
                        </button>
                    </div>
                </div>

            )}
        </>
    );
};

export default Modal;
