import React from 'react'
import Whatsappicon from "../assets/icons/whatsapp (2).png"

const Whatsapp = () => {
    return (
        <div className="fixed z-10 ml-5 bg-white">
            <a href="https://api.whatsapp.com/send?phone=16478063030"
                class="whatsapp-button" target="_blank"
                style={{ position: "fixed", bottom: "5px" ,right :'10px'}}
            >
                <img src={Whatsappicon} alt="whatsapp" className='h-25 w-56'/>
            </a>
        </div>
    )
}

export default Whatsapp;