import React from 'react';
import ClientsReview from '../components/ClientsReview';
import Hero from '../components/Hero';
import HeroImage from "../assets/images/paint2.png";
import Advantages from '../components/Advantages';
import { Link } from 'react-router-dom';

const Services = () => {
    const data = [
        {
            title: "Interior Painting",
            text: "Our interior painting services breathe new life into your home or office. Whether it's refreshing existing colors or creating entirely new palettes, our team ensures flawless results.",
            image: "https://5.imimg.com/data5/SELLER/Default/2021/1/MY/IV/BZ/68677091/indoor-decoration-500x500.jpg",
        },
        {
            title: "Exterior Painting",
            text: "Protect and enhance the exterior of your property with our professional painting services. We use durable, weather-resistant paints to ensure longevity and beauty.",
            image: "https://hips.hearstapps.com/hmg-prod/images/small-garden-ideas-hbx110117carretero10-copy-1653688248.jpg?crop=0.651xw:1.00xh;0.249xw,0&resize=640:*",
        },
        {
            title: "Commercial Painting",
            text: "Impress clients and create a welcoming atmosphere in your commercial space with our commercial painting services. From offices to retail outlets, we tailor our services to meet your unique needs.",
            image: "https://s3-us-west-2.amazonaws.com/issuewireassets/primg/48276/commercial-p1322962248.jpg",
        },
        {
            title: "Residential Painting",
            text: "Your home is your sanctuary, and we treat it as such. Our residential painting services bring your vision to life, turning your house into a personalized haven.",
            image: "https://certapro.com/wp-content/uploads/cache//CertaPro_Painting_Closeup_31888_MASKED_WEB/1502312629.jpg",
        },
        {
            title: "Cabinet Painting Services",
            text: "Revitalize your cabinets with our specialized painting services. Whether you're looking to update the look of your kitchen or bathroom, our expert team delivers stunning results.",
            image: "https://assets-global.website-files.com/62960e65d2ca0638c9fa302b/62a4769e4262189d65ad8deb_a.png",
        },
        {
            title: "Stain",
            text: "Enhance the natural beauty of wood surfaces with our professional staining services. From furniture to decks, we offer staining services to suit your preferences.",
            image: "https://www.familyhandyman.com/wp-content/uploads/2023/10/How-Long-Does-Wood-Stain-Take-To-Dry_GettyImages-184861094_YVedit.jpg",
        },
        {
            title: "Custom Pieces",
            text: "Elevate your space with custom-painted pieces that reflect your style and personality. From accent walls to unique furniture, we bring your ideas to life with our artistic touch.",
            image: "https://oneofakindmosaics.com/wp-content/uploads/2016/10/brown-and-white-painting-tree-branches-6-piece-multi-panel-modern-abstract-huge-original-art-large-burnt-orange-custom-72x36-57fe79445.jpg",
        },

    ];

    return (
        <div>
            <Hero image={HeroImage} title="Our Services" />
            <div className='bg-cover bg-center p-4'
            // style={{ backgroundImage: `url(${Bursh})`, width: '100%', minheight: '100vh' }}
            >
                <div className=' flex justify-center items-center gap-2 mt-10'>
                    <div className='h-[2px] bg-[#f15a2d] w-[40%]'></div>
                    <div className='flex justify-center items-center text-gray-900'>
                        <h2 className="text-[15px] md:text-[25px] font-bold whitespace-nowrap">
                            Quality Care Painting Services
                        </h2>
                    </div>
                    <div className='h-[2px] w-[40%] bg-[#f15a2d]'></div>
                </div>
                <p className="text-xl leading-relaxed justify-center flex text-center text-gray-800 items-center mt-5 mb-10">
                    At Quality Care Painting, we bring life and vibrancy to your spaces through our expert painting services. With a commitment to quality and care, we transform interiors and exteriors, both commercial and residential, into stunning masterpieces. Our dedication to excellence extends to every project, from a single room to entire properties.
                </p>
            </div>
            {data.map((item, index) => (
                <div
                    key={index}
                    className={`flex max-w-[1070px] mx-auto flex-col md:flex-row relative 
                    ${index % 2 === 0 ? '' : 'md:flex-row-reverse'}`}
                >
                    <div className="w-full md:w-[60%] flex items-center justify-center py-[4%] flex-1 relative">
                        <img src={item?.image} className="h-[400px] w-full" alt="Services" />
                        <div className="absolute bg-transparent h-full w-full top-0 left-0"></div>
                    </div>
                    <div className="w-full md:w-[40%] md:pl-10 sm:pl-10 sm:pr-2 extrasm:pl-7 extrasm:pr-4 flex flex-1 justify-center flex-col py-[4%]">
                        <h1 className='text-2xl font-bold text-gray-800'>
                            {item?.title}
                        </h1>
                        <p className="text-gray-900 text-xl mt-8 ">
                            {item?.text}
                        </p>
                        {/* <div className="mt-6">
                            <Link to="/price">
                                <button className=' block py-3 px-6 text-md md:text-xl font-[100] bg-gray-800 text-white rounded-[200px] '>
                                    Get a FREE Quote Now!
                                </button>
                            </Link>
                        </div> */}
                    </div>
                </div>
            ))}
            <Advantages />
            <ClientsReview />
        </div>
    );
}

export default Services;
