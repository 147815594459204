import React, { useState } from 'react';
import SpecificQ5 from './SpecificQ5';

const SpecificQ4 = ({ updateProgress }) => {
    const [selectedOption4, setselectedOption4] = useState(null);
    const [Specificuestion5, setSpecificquestion5] = useState(false);

    const handleOptionChange4 = (event) => {
        setselectedOption4(event.target.value);
        updateProgress(80);
        setSpecificquestion5(true);
    };

    return (
        <div className='mb-5'>
            <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
                4. Do you need it painted on a specific date?
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5 border border-white border-opacity-50 rounded-md px-5 py-3"
                style={{ background: "linear-gradient(to top left, rgba(95, 145, 124, 1.0), rgba(67, 118, 143, 1.0))" }}
            >
                <div className="rounded-md px-3 py-2 flex ">
                    <input type="radio" id="pickcolour" name="Specificdate" value="pickcolour" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out "
                        checked={selectedOption4 === "pickcolour"} onChange={handleOptionChange4} />
                    <div className='flex flex-col'>
                        <label htmlFor="pickcolour" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>No, I'm flexible</h4>
                        </label>
                        <span className="text-white ml-2">5% OFF</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="radio" id="colorChange" name="Specificdate" value="colorChange" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out"
                        checked={selectedOption4 === "colorChange"} onChange={handleOptionChange4} />
                    <div className='flex flex-col'>
                        <label htmlFor="colorChange" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>
                                Yes, I need it painted on/by a specific date</h4>
                        </label>
                        <span className="text-white ml-2">FREE</span>
                    </div>
                </div>
            </div>
            {Specificuestion5 && <SpecificQ5 updateProgress={updateProgress} />}
        </div>
    );
};

export default SpecificQ4;
