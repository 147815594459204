import React from 'react'

const Advantages = () => {
  return (
    <div className='flex flex-col justify-center items-center bg-[#e9eff4]'>
      <div className='w-[95%] flex justify-center items-center gap-2 mt-10'>
        <div className='h-[2px] bg-[#f15a2d] w-[40%]'></div>
        <div className='flex justify-center items-center text-gray-900'>
          <h2 className="text-[15px] md:text-[25px] font-bold whitespace-nowrap">
            Why Choose Us?
          </h2>
        </div>
        <div className='h-[2px] w-[40%] bg-[#f15a2d]'></div>
      </div>
      <div className='flex justify-center items-center flex-col'>
        <p className="text-xl leading-relaxed justify-center flex text-center text-gray-800 items-center w-[80vw] mt-5 mb-10">
          By choosing us, you're choosing a painting company that is dedicated to delivering exceptional results and exceeding your expectations every step of the way.
        </p>
        <div class='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-between mx-4 max-w-[1300px]'>
          <div class='bg-white p-8  rounded'>
            <h1 className="text-2xl font-bold text-gray-800">
              Quality Workmanship
            </h1>
            <p class="text-lg text-gray-800 mt-4 leading-relaxed">
              We pride ourselves on delivering top-notch craftsmanship on every project, ensuring that your painting needs are met with precision and care.
            </p>
          </div>
          <div class='bg-white p-8  rounded'>
            <h1 className="text-2xl font-bold text-gray-800">
              Attention to Detail
            </h1>
            <p class="text-lg text-gray-800 mt-4 leading-relaxed">
              From thorough surface preparation to meticulous finishing touches, we pay close attention to every detail to ensure a flawless result that exceeds your expectations.
            </p>
          </div>
          <div class='bg-white p-8  rounded'>
            <h1 className="text-2xl font-bold text-gray-800">
              Professionalism
            </h1>
            <p class="text-lg text-gray-800 mt-4 leading-relaxed">
              Our team is dedicated to providing exceptional customer service, from prompt communication to respecting your property and schedule throughout the painting process.
            </p>
          </div>
          <div class='bg-white p-8  rounded'>
            <h1 className="text-2xl font-bold text-gray-800">
              Customized Solutions
            </h1>
            <p class="text-lg text-gray-800 mt-4 leading-relaxed">
              Whether you have a specific vision in mind or need guidance on color selection and design, we work closely with you to tailor our services to your unique preferences and requirements.
            </p>
          </div>
          <div class='bg-white p-8  rounded'>
            <h1 className="text-2xl font-bold text-gray-800">
              Reliable and Trustworthy
            </h1>
            <p class="text-lg text-gray-800 mt-4 leading-relaxed">
              With years of experience in the industry, you can trust us to deliver reliable and consistent results, backed by our commitment to professionalism and integrity.
            </p>
          </div>
          <div class='bg-white p-8  rounded'>
            <h1 className="text-2xl font-bold text-gray-800">
              Affordable Pricing
            </h1>
            <p class="text-lg text-gray-800 mt-4 leading-relaxed">

              We offer competitive pricing without compromising on quality, providing excellent value for your investment in transforming your space with our painting services.
            </p>
          </div>
          <div class='bg-white p-8  rounded mb-5'>
            <h1 className="text-2xl font-bold text-gray-800">
              Satisfaction Guaranteed
            </h1>
            <p class="text-lg text-gray-800 mt-4 leading-relaxed">
              Your satisfaction is our priority, and we stand behind the quality of our work. We're not satisfied until you're thrilled with the final outcome.
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Advantages;