import React from 'react'
import Hero from '../components/Hero'
import Testimonial from '../components/Testimonial'
import OurWork from '../components/OurWork'
import Advantages from '../components/Advantages'
import HeroImage from "../assets/images/paint6.jpeg";

const Home = () => {
  return (
    <div >
      <Hero image={HeroImage} title="Your Vision, Our Paint" />
      <div className="h-[280px] bg-white w-full text-gray-800 flex justify-center items-center">
        <h1 className="text-4xl font-[150]">
          You ask <span className="font-bold">We do</span>
        </h1>
      </div>
      <Testimonial />
      <div className="py-12 pl-8 md:pl-[32%] pr-8 bg-[#e9eff4]">
        <h1 className="text-2xl font-bold text-gray-800">
          The Quality Care Painting Specialists!
        </h1>
        <p className="text-lg text-gray-800 mt-8">
          Whatever painting project you have in mind, <br /> Quality Care Painting can
          accomplish this for you! Unlike our competitors, we offer <b className="font-semibold"> industry
            leading transparancy and standardized pricing. </b>
          {/* <br className="my-8" />
          <p className="pt-4">
            Whether you want one or
            two rooms, accent walls or your entire quality care painted, building a price
            and ordering your quality care painting project online has never been easier.
            Gone are the days of waiting for tradespeople to come and quote your
            project, wondering &quot;Did I get a fair price?&quot;. There is no obligation.
            Build your condo price today!
          </p> */}
        </p>
        {/* <h1 className="font-bold text-2xl text-gray-800 mt-8">
          Why We're the Right Choice
        </h1> */}
        {/* <p className="text-lg text-gray-800 mt-4">
          We specialize in condo painting in Ontaro and are experts <br /> when it comes to the details. From security personnel and
          coordinating with property management, to material
          deliveries and service elevators,<br /> we’ll ensure your condo painting
          experience is a seamless one. <br /> Every project is completed with a professional level of detail and condo, keeping you,
          the homeowner in mind.
          <br className="my-8" />
          <p className="pt-4">
            We use only cutting edge practices and techniques, high quality paints, and provide an industry leading
            <b className='font-semibold'> 3 year warranty </b>on every condo painting project. Your space is important, you deserve the best!
          </p>
        </p> */}
      </div>
      <OurWork />
      <Advantages />
    </div>
  )
}

export default Home