import React, { useState } from 'react';
import Doors from './Doors';
import DoorFrames from './DoorFram';
import Ceiling from './Ceiling';
import Walls from './Walls';
import Bathroom from './Bathroom';
import Closet from './Closet';

const Question6 = ({ updateProgress }) => {
    const [selectedDoor, setSelectedDoor] = useState(null);
    const [ShowDoor, setShowDoor] = useState(false);
    const [DoorFrame, setDoorFrame] = useState(null);
    const [ShowDoorFrame, setShowDoorFrame] = useState(false);
    const [CeilingPaint, setCeilingpaint] = useState(null);
    const [Showceiling, setShowceiling] = useState(false);
    const [WallsPaint, setWallspaint] = useState(null);
    const [ShowWall, setShowWalls] = useState(false);
    const [BathroomPaint, setBathroomPaint] = useState(null);
    const [ShowBathroomPaint, setShowBathroomPaint] = useState(false);
    const [ClosetPaint, setClosetPaint] = useState(null);
    const [ShowClosetPaint, setShowClosetPaint] = useState(false);

    const handleDoors = (event) => {
        const selectedValue = event.target.value;
        if (selectedDoor === selectedValue) {
            setSelectedDoor(null);
            updateProgress(100);
            setShowDoor(false);
        } else {
            setSelectedDoor(selectedValue);
            updateProgress(84);
            setShowDoor(true);
        }
    };
    const handleDoorsFrame = (event1) => {
        const selectframe = event1.target.value;
        if (DoorFrame === selectframe) {
            setDoorFrame(null);
            updateProgress(100);
            setShowDoorFrame(false);
        } else {
            setDoorFrame(selectframe);
            updateProgress(86);
            setShowDoorFrame(true);
        }
    };
    const ceilingselect = (event) => {
        const selectceiling = event.target.value;
        if (CeilingPaint === selectceiling) {
            setCeilingpaint(null);
            updateProgress(100);
            setShowceiling(false);
        } else {
            setCeilingpaint(selectceiling);
            setShowceiling(true);
        }
    };
    const Wallsselect = (event) => {
        const selectwalls = event.target.value;
        if (WallsPaint === selectwalls) {
            setWallspaint(null);
            updateProgress(100);
            setShowWalls(false);
        } else {
            setWallspaint(selectwalls);
            updateProgress(84);
            setShowWalls(true);
        }
    };
    const Bathroomselect = (event) => {
        const selectbath = event.target.value;
        if (BathroomPaint === selectbath) {
            setBathroomPaint(null);
            updateProgress(100);
            setShowBathroomPaint(false);
        } else {
            setBathroomPaint(selectbath);
            updateProgress(84);
            setShowBathroomPaint(true);
        }
    };
    const SelectCloset = (event) => {
        const selectcloset = event.target.value;
        if (ClosetPaint === selectcloset) {
            setClosetPaint(null);
            updateProgress(100);
            setShowClosetPaint(false);
        } else {
            setClosetPaint(selectcloset);
            updateProgress(84);
            setShowClosetPaint(true);
        }
    };

    return (
        <div className='mb-5'>
            <p className="text-white text-[18pt] leading-[22pt] font-light border border-White mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
                6. What else would you like us to paint?
            </p>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-5 border border-White border-opacity-50 rounded-md px-5 py-3"
                style={{ background: "linear-gradient(to top left, rgba(95, 145, 124, 1.0), rgba(67, 118, 143, 1.0))" }}
            >
                <div className="rounded-md px-3 py-2 flex ">
                    <input type="checkbox" id="refreshColors6" name="paintJob6" value="refreshColors6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out "

                    />
                    <div className='flex flex-col'>
                        <label htmlFor="refreshColors6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Baseboards</h4>
                        </label>
                        <span className="text-white ml-2">$299.00</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="door" name="paintJob6" value="door" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                        checked={selectedDoor === "door"} onChange={handleDoors}
                    />
                    <div className='flex flex-col'>
                        <label htmlFor="door" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out"
                        >
                            <h4>Doors</h4>
                        </label>
                        <span className="text-white ml-2">$50.00 each</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="doorframe" name="paintJob6" value="doorframe" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                        checked={DoorFrame === "doorframe"} onChange={handleDoorsFrame}
                    />
                    <div className='flex flex-col'>
                        <label htmlFor="doorframe" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Door Frames</h4>
                        </label>
                        <span className="text-white ml-2">$40.00 each</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="ceiling" name="paintJob6" value="ceiling" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                        checked={CeilingPaint === "ceiling"} onChange={ceilingselect}
                    />
                    <div className='flex flex-col'>
                        <label htmlFor="ceiling" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Ceiling</h4>
                        </label>
                        <span className="text-white ml-2">$499.00+</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"

                    />
                    <div className='flex flex-col'>
                        <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>10+ High Walls/Ceilings</h4>
                        </label>
                        <span className="text-white ml-2">$100.00</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"

                    />
                    <div className='flex flex-col'>
                        <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Crown Molding</h4>
                        </label>
                        <span className="text-white ml-2">$399.00</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="accentwall" name="paintJob6" value="accentwall" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                        checked={WallsPaint === "accentwall"} onChange={Wallsselect}
                    />
                    <div className='flex flex-col'>
                        <label htmlFor="accentwall" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Accent Wall(s)</h4>
                        </label>
                        <span className="text-white ml-2">$129.00 each</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"

                    />
                    <div className='flex flex-col'>
                        <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Den</h4>
                        </label>
                        <span className="text-white ml-2">$99.00</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="bathroompaint" name="paintJob6" value="bathroompaint" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                        checked={BathroomPaint === "bathroompaint"} onChange={Bathroomselect}
                    />
                    <div className='flex flex-col'>
                        <label htmlFor="bathroompaint" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Bathroom(s) </h4>
                        </label>
                        <span className="text-white ml-2">$199.00 each</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="checkbox" id="closetpaint" name="paintJob6" value="closetpaint" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                        checked={ClosetPaint === "closetpaint"} onChange={SelectCloset}
                    />
                    <div className='flex flex-col'>
                        <label htmlFor="closetpaint" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Closet(s)</h4>
                        </label>
                        <span className="text-white ml-2">$65.00 each</span>
                    </div>
                </div>
            </div>
            {ShowDoor && <Doors updateProgress={updateProgress} />}
            {ShowDoorFrame && <DoorFrames updateProgress={updateProgress} />}
            {Showceiling && <Ceiling updateProgress={updateProgress} />}
            {ShowWall && <Walls updateProgress={updateProgress} />}
            {ShowBathroomPaint && <Bathroom updateProgress={updateProgress} />}
            {ShowClosetPaint && <Closet updateProgress={updateProgress} />}
        </div>
    );
};

export default Question6;
