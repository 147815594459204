import React, { useState } from 'react';
import Questio4 from './Question4';

const Questio3 = ({ updateProgress }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [showQuestion4, setShowQuestion4] = useState(null);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        updateProgress(75);
        setShowQuestion4(true);
    };

    return (
        <div className='mb-5'>
            <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
                3. What kind of paint job do you need?
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5 border border-white border-opacity-50 rounded-md px-5 py-3"
                style={{ background: "linear-gradient(to top left, rgba(95, 145, 124, 1.0), rgba(67, 118, 143, 1.0))" }}
            >
                <div className="rounded-md px-3 py-2 flex ">
                    <input type="radio" id="refreshColors" name="paintJob" value="refreshColors" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out "
                        checked={selectedOption === "refreshColors"} onChange={handleOptionChange} />
                    <div className='flex flex-col'>
                        <label htmlFor="refreshColors" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Refresh Existing Colors</h4>
                        </label>
                        <span className="text-white ml-2">$499.00</span>
                    </div>
                </div>
                <div className=" flex  rounded-md px-3 py-2">
                    <input type="radio" id="colorChange" name="paintJob" value="colorChange" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out"
                        checked={selectedOption === "colorChange"} onChange={handleOptionChange} />
                    <div className='flex flex-col'>
                        <label htmlFor="colorChange" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                            <h4>Color Change</h4>
                        </label>
                        <span className="text-white ml-2">$749.00</span>
                    </div>
                </div>
            </div>
            {showQuestion4 && <Questio4 updateProgress={updateProgress} />}
        </div>
    );
};

export default Questio3;
