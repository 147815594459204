import React from "react";
import ImageBack from "../assets/images/wall.jpg";
import Workimg1 from "../assets/images/Workimg(1).jpeg"
import Workimg2 from "../assets/images/Workimg(2).jpeg"
import Workimg3 from "../assets/images/Workimg(3).jpeg"
import Workimg4 from "../assets/images/Workimg(4).jpeg"
import Workimg5 from "../assets/images/Workimg(5).jpeg"
import Workimg6 from "../assets/images/Workimg(6).jpeg"
import Workimg7 from "../assets/images/Workimg(7).jpeg"
import Workimg8 from "../assets/images/Workimg(8).jpeg"

const OurWork = () => {
  return (
    <div className="relative">
      <div className="min-h-[400px] md:min-h-[146vh] py-12 px-8 w-full relative z-10  md:bg-black bg-transparent md:bg-opacity-60" >
        <div className="h-full w-full">
          <div className=' flex justify-center items-center gap-2 mt-10'>
            <div className='h-[2px] bg-[#f15a2d] w-[40%]'></div>
            <div className='flex justify-center items-center text-gray-900'>
              <h2 className="text-xl lg:text-white md:text-[25px] sm:text-gray-900 font-semibold whitespace-nowrap">
                Some of Our Work
              </h2>
            </div>
            <div className='h-[2px] w-[40%] bg-[#f15a2d]'></div>
          </div>
          <div className="mt-8 flex gap-4 flex-wrap">
            <img
              src={Workimg1}
              alt="Hero Image"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
            <img
              src={Workimg2}
              alt="Hero Image1"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
            <img
              src={Workimg3}
              alt="Hero Image2"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
            <img
              src={Workimg4}
              alt="Hero Image3"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
            <img
              src={Workimg5}
              alt="Hero Image4"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
            <img
              src={Workimg6}
              alt="Hero Image4"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
            <img
              src={Workimg7}
              alt="Hero Image4"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
            <img
              src={Workimg8}
              alt="Hero Image5"
              layout="fill"
              objectFit="cover"
              className="h-[270px] object-cover w-full md:w-[24%] rounded-lg"
            />
          </div>
        </div>
      </div>
      <div className="absolute inset-0 z-[-10] min-h-[400px] md:h-[110vh]">
        <img
          src={ImageBack}
          alt="Hero Image6"
          layout="fill"
          objectFit="cover"
          className="min-h-[400px] sm:hidden md:block md:min-h-[130vh] object-cover w-full"
        />
      </div>
    </div>
  );
};

export default OurWork;
