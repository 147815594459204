import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Star, StarHalf } from "lucide-react";

const testimonialList = [
    {
        author: {
            fullName: "Sarah Thompson",
            picture: "https://cdn.easyfrontend.com/pictures/users/user22.jpg",
        },
        rating: 3.5,
        description:
            "Absolutely thrilled with the results! Quality Care Painting completely transformed my home with their meticulous attention to detail. Couldn't be happier!"
    },
    {
        author: {
            fullName: "Michael Rodriguez",
            picture: "https://cdn.easyfrontend.com/pictures/users/user4.jpg",
        },
        rating: 4,
        description:
            "Professionalism at its finest! From start to finish, the team at Quality Care Painting exceeded my expectations. Highly recommend their services!"
    },
    {
        author: {
            fullName: "Emily Johnson",
            picture: "https://cdn.easyfrontend.com/pictures/users/user20.jpg",
        },
        rating: 5,
        description:
            "Incredible craftsmanship! Quality Care Painting brought my vision to life with their custom painting services. I'm amazed at the level of skill and expertise they possess."
    },
    {
        author: {
            fullName: "David Smith",
            picture: "https://cdn.easyfrontend.com/pictures/users/user21.jpg",
        },
        rating: 3.5,
        description:
            "Top-notch service! Quality Care Painting tackled my commercial project with ease, delivering impeccable results on time and within budget. Will definitely be using them again!"
    },
    {
        author: {
            fullName: "Jennifer Brown",
            picture: "https://cdn.easyfrontend.com/pictures/users/user18.jpg",
        },
        rating: 4,
        description:
            "Amazing job on my cabinets! Quality Care Painting restored them to their former glory with a fresh coat of paint. They look brand new! Thank you so much!"
    },
];

const Rating = ({ rating, showLabel, className }) => (
    <p className={className}>
        {[...Array(5)].map((_, i) => {
            const index = i + 1;
            let content = "";
            if (index <= Math.floor(rating))
                content = <Star key={i} className="text-yellow-500" />;
            else if (rating > i && rating < index + 1)
                content = <StarHalf key={i} className="text-yellow-500" />;
            else if (index > rating)
                content = <Star key={i} className="text-yellow-200 dark:text-opacity-20" />;

            return <Fragment key={i}>{content}</Fragment>;
        })}
        {showLabel && <span>{rating.toFixed(1)}</span>}
    </p>
);

const TestimonialItem = ({ testimonial }) => (
    <div className="bg-white border mr-2 rounded-2xl p-6 lg:p-8 h-[280px] md:h-[250px] cursor-pointer">
        {/* <img
            src={testimonial.author.picture}
            alt={testimonial.author.fullName}
            className="h-auto rounded-full mb-6 border"
            width="100"
        /> */}
        <h4 className="text-xl font-medium mb-1">{testimonial.author.fullName}</h4>
        <Rating rating={testimonial.rating} showLabel={false} className="mb-6 flex flex-row" />
        <p className="opacity-80">{testimonial.description}</p>
    </div>
);

const ClientsReview = () => {
    const sliderSettings1 = {
        // dots: true,
        infinite: true,
        speed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1,
        cssEase: 'linear',
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    return (
        <section className="ezy__testimonial7 light py-12 px-8 bg-[#e9eff4]">
            <div className="container  mx-auto">
                <div className="grid grid-cols-12 mb-6 md:mb-12">
                    <div className="col-span-12 lg:col-span-5">
                        <h2 className="text-2xl leading-none md:text-[35px] font-bold mb-6">
                            Everyone should believe in What Our Client Say.
                        </h2>
                    </div>
                    {/* <div className="col-span-12 lg:col-span-4 lg:pl-12">
                        <p className="text-lg opacity-80">
                            It&apos;s easier to reach your savings goals when you have the right
                            savings account. Take a look and find the right one for you!
                        </p>
                    </div> */}
                </div>

                <Slider {...sliderSettings1} className="flex justify-center mb-8">
                    {testimonialList.map((testimonial, i) => (
                        <div key={i}>
                            <TestimonialItem testimonial={testimonial} />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default ClientsReview;
