import React, { useState, useEffect } from 'react'
import Hero from '../components/Hero'
import Wallimg from "../assets/images/wall.jpg"
import Wholeroom from "../assets/icons/Wholeroom.png"
import Specificrooms from "../assets/icons/SpecificRooms.png"

import Wholebutton from './Wholebutton'
import Specificroom from './Specificroom'

const Price = () => {
  const [showSecondQuestion, setShowSecondQuestion] = useState(false);
  const [showSpecificRooms, setshowSpecificRooms] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isProgressBarFixed, setIsProgressBarFixed] = useState(false);
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 100;
      const isScrollingDown = window.scrollY > threshold;

      setIsProgressBarFixed(isScrollingDown);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleWholeCondoClick = () => {
    setShowSecondQuestion(true);
    setActiveButton('wholeCondo');
    setProgress(50);
    setshowSpecificRooms(false);
  };
  const handleSpecificClick = () => {
    setshowSpecificRooms(true);
    setActiveButton('specificRooms');
    setProgress(50)
    setShowSecondQuestion(false);
  };
  const updateProgress = (value) => {
    setProgress(value);
  };
  return (
    <div className='bg-[#3d8180]'>
      <Hero image={Wallimg} title="Our Price" />
      <div className='w-[97%] ml-5'>
        <h1 className="text-[3.5vw] font-[200] text-white">
          Build a price in less than 2 minutes!
        </h1>
        <div className={`w-full h-10 bg-[#6a834c] z-30 rounded-full dark:bg-gray-700
        ${isProgressBarFixed ? 'fixed top-5 left-0 z-10 ' : ''}`}>
          <div className="h-10 bg-[#d3811c] rounded-full dark:bg-[#d3811c]" style={{ width: `${progress}%` }}>
            <div className='flex justify-center items-center text-center text-white h-10'>
              {progress}%
            </div>
          </div>
        </div>
        <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4 mb-10">
          Includes high-quality low VOC paint, minor wall prep, and all other materials!
        </p>
        <div>
          <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
            1. How much of your condo needs painting?
          </p>
          <div className='flex justify-center border border-white border-opacity-50 mb-10'>
            <button
              className={`flex flex-col items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg w-[50%] border-r border-white border-opacity-50 bg-gradient-to-t 
              ${activeButton === 'wholeCondo' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'} hover:from-[#EE9301] hover:bg-[#E5601F]`}
              onClick={handleWholeCondoClick}
            >
              <img src={Wholeroom} alt="Whole Room" className="h-20 w-20 mb-2" />
              <p className="text-white">Whole Condo</p>
            </button>
            <button
              className={`flex flex-col items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg w-[50%] border-r border-white border-opacity-50 bg-gradient-to-t 
              ${activeButton === 'specificRooms' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'} hover:from-[#EE9301] hover:bg-[#E5601F]`}
              onClick={handleSpecificClick}
            >
              <img src={Specificrooms} alt="Specific Rooms" className="w-20 h-20 mb-2" />
              <p className="text-white">Specific Rooms</p>
            </button>
          </div>
        </div>
        {showSecondQuestion && (
          <Wholebutton updateProgress={updateProgress} />
        )}
        {showSpecificRooms && (
          <Specificroom updateProgress={updateProgress} />
        )}
      </div>
    </div>
  )
}

export default Price;
