import Modal from "./pages/Model";
import Routing from "./pages/Routing";

function App() {
  return (
    <>
      <Modal />
      <Routing />
    </>
  );
}

export default App;
