import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../assets/icons/Logo.png"

const Hero = ({ image, title }) => {
  return (
    <div className="relative h-[500px] lg:h-screen">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white opacity-50"></div>
      <div className="absolute inset-0 bg-cover bg-center flex flex-col items-center justify-center pt-16"
        style={{ backgroundImage: `linear-gradient(155deg, rgba(255,255,255,1) 31%, rgba(255,255,255,0) 95%), url(${image})` }}>
        <img src={Logo} alt="Logo" 
        className="absolute left-4 top-[15.5%]  xl:top-[20%]  md:top-[20%] w-[16%] md:w-[16%] xl:w-[16%] extrasm:top-[26%]" />
        <h1 className='text-[5vw] lg:text-[3vw] text-gray-800 font-[700] '>{title}</h1>
        {/* <p className='mt-8 block py-3 px-6 text-md md:text-xl font-[100] bg-gray-800 text-white rounded-[200px] '>
          <Link to="/price">
            Get a FREE Quote Now!
          </Link>
        </p> */}
      </div>
    </div>
  );
}

export default Hero;
