import React, { useState } from 'react';

const SpecificQ5 = ({ updateProgress }) => {
    const [selectedOption5, setSelectedOption5] = useState(null);
    // const [Showquestion6, setShowquestion6] = useState(false);

    const handleOptionChange5 = (event) => {
        setSelectedOption5(event.target.value);
        updateProgress(100);
        // setShowquestion6(true);
    };

    return (
        <div className='mb-5 border border-white border-opacity-50 mt-5'>
            <p className="text-white text-[18pt] leading-[22pt] font-light border-b border-white border-opacity-50 bg-white bg-opacity-20 px-5 py-4">
                5. What do you want to do to Room 1?
            </p>
            <div className="px-5 py-4"
                style={{ background: "linear-gradient(to top left, rgba(95, 145, 124, 1.0), rgba(67, 118, 143, 1.0))" }}
            >
                <div className='border p-5 text-[18pt] leading-[22pt] font-light border-white'>
                    <h3 className="text-white">
                        1. Name of room
                    </h3>
                    <input type="text" className="w-full py-2 my-2 bg-transparent border-b border-white text-white focus:outline-none" placeholder="Enter room name" />
                </div>
                <div className='border mt-5 p-5 text-[18pt] leading-[22pt] font-light border-white'>
                    <h3 className="text-white">
                        2. What is the approx. square footage of the room?
                    </h3>
                    <input type="range" className="w-full py-2 my-2"/>
                    {/* Progress bar can be added here */}
                </div>
                <div className='border p-5 text-[18pt] mt-5 leading-[22pt] font-light border-white'>
                    <h3 className="text-white">
                        3. What kind of paint job does the room need?
                    </h3>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-5'>
                        <div className="flex">
                            <input type="radio" id="refreshColors" name="paintJob" value="refreshColors" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out " />
                            {/* <label htmlFor="refreshColors" className="ml-2 text-white">Refresh Existing Colors - $499.00</label> */}
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>
                                        Refresh Existing Colors</h4>
                                </label>
                                <span className="text-white ml-2">$499.00</span>
                            </div>
                        </div>
                        <div className="flex">
                            <input type="radio" id="colorChange" name="paintJob" value="colorChange" className="form-radio h-7 w-7 cursor-pointer transition duration-300 ease-in-out" />
                            {/* <label htmlFor="colorChange" className="ml-2 text-white">Color Change - $749.00</label> */}
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>
                                        Color Change </h4>
                                </label>
                                <span className="text-white ml-2">$749.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border p-5 text-[18pt] mt-5 leading-[22pt] font-light border-white'>
                    <h3 className="text-white">
                        4. What else would you like us to paint?
                    </h3>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5">
                        <div className="rounded-md px-3 py-2 flex ">
                            <input type="checkbox" id="refreshColors6" name="paintJob6" value="refreshColors6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out "
                            // checked={selectedOption6 === "refreshColors6"} onChange={handleOptionChange6}
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="refreshColors6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>Baseboards</h4>
                                </label>
                                <span className="text-white ml-2">$125.00</span>
                            </div>
                        </div>
                        <div className=" flex  rounded-md px-3 py-2">
                            <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                            // checked={selectedOption6 === "colorChange6"} onChange={handleOptionChange6} 
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>Door Slabs</h4>
                                </label>
                                <span className="text-white ml-2">$50.00 each</span>
                            </div>
                        </div>
                        <div className=" flex  rounded-md px-3 py-2">
                            <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                            // checked={selectedOption6 === "colorChange6"} onChange={handleOptionChange6} 
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>Door Frames</h4>
                                </label>
                                <span className="text-white ml-2">$40.00 each</span>
                            </div>
                        </div>
                        <div className=" flex  rounded-md px-3 py-2">
                            <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                            // checked={selectedOption6 === "colorChange6"} onChange={handleOptionChange6} 
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>Ceiling</h4>
                                </label>
                                <span className="text-white ml-2">$125.00+</span>
                            </div>
                        </div>
                        <div className=" flex  rounded-md px-3 py-2">
                            <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                            // checked={selectedOption6 === "colorChange6"} onChange={handleOptionChange6} 
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>10'+ High Walls/Ceilings</h4>
                                </label>
                                <span className="text-white ml-2">$0.00</span>
                            </div>
                        </div>
                        <div className=" flex  rounded-md px-3 py-2">
                            <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                            // checked={selectedOption6 === "colorChange6"} onChange={handleOptionChange6} 
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>Crown Molding</h4>
                                </label>
                                <span className="text-white ml-2">$128.00</span>
                            </div>
                        </div>
                        <div className=" flex  rounded-md px-3 py-2">
                            <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                            // checked={selectedOption6 === "colorChange6"} onChange={handleOptionChange6} 
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>Accent Wall(s)</h4>
                                </label>
                                <span className="text-white ml-2">$129.00 each</span>
                            </div>
                        </div>
                        <div className=" flex  rounded-md px-3 py-2">
                            <input type="checkbox" id="colorChange6" name="paintJob6" value="colorChange6" className="form-checkbox h-[3vh] w-[3vw] cursor-pointer transition duration-300 ease-in-out"
                            // checked={selectedOption6 === "colorChange6"} onChange={handleOptionChange6} 
                            />
                            <div className='flex flex-col'>
                                <label htmlFor="colorChange6" className="ml-2 text-white  cursor-pointer transition duration-300 ease-in-out">
                                    <h4>Closet(s)</h4>
                                </label>
                                <span className="text-white ml-2">$65.00 each</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border p-5 text-[18pt] leading-[22pt] font-light border-white'>
                    <h3 className="text-white">
                        5. How many doors slabs need painting?
                    </h3>
                    <input type="text" className="w-full py-2 my-2 bg-transparent border-b border-white text-white focus:outline-none" placeholder="Enter room name" />
                </div>
            </div>
        </div>

    );
};

export default SpecificQ5;
