import React, { useState } from 'react'
import Pickcolourhelp from './Pickcolourhelp';

const Specificroom = ({ updateProgress }) => {

    const [Question3, setQuestion3] = useState(false);
    const [activeButton, setActiveButton] = useState(null);


    const handleClick = (buttonName) => {
        updateProgress(67);
        setQuestion3(true);
        setActiveButton(buttonName);

    };
    return (
        <div>
            <p className="text-white text-[18pt] leading-[22pt] font-light border border-white mt-5 border-opacity-50 rounded-md bg-white bg-opacity-20 px-5 py-4">
                2. How many rooms need painting?
            </p>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-center border border-white border-opacity-50 mb-10'>
                <button
                    className={`flex flex-col bg-gradient-to-t 
              ${activeButton === '1room' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
              hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r border-white border-opacity-50`}
                    onClick={() => handleClick('1room')}
                >
                    <p className="text-white">1 Room</p>
                </button>
                <button
                    className={`flex flex-col bg-gradient-to-t 
                    ${activeButton === '2room' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
                    hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r border-white border-opacity-50`}
                    onClick={() => handleClick('2room')}
                >
                    <p className="text-white">2 Rooms</p>
                </button>
                <button
                    className={`flex flex-col bg-gradient-to-t 
                     ${activeButton === '3room' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
                     hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r border-white border-opacity-50`}
                    onClick={() => handleClick('3room')}
                >
                    <p className="text-white">3 Rooms</p>
                </button>
                <button
                    className={`flex flex-col bg-gradient-to-t 
                     ${activeButton === '4room' ? 'from-[#EE9301] to-[#E5601F]' : 'from-[#5D95A2] to-[#7DAFBA]'}
                     hover:from-[#EE9301] hover:bg-[#E5601F] items-center justify-center text-lg font-semibold px-8 py-4 shadow-lg border-r border-white border-opacity-50`}
                    onClick={() => handleClick('4room')}
                >
                    <p className="text-white">4 Rooms</p>
                </button>
            </div>
            {Question3 && <Pickcolourhelp updateProgress={updateProgress} />}

        </div>
    )
}

export default Specificroom